.nhs-button-warning {
  background-color: #d5281b;
  box-shadow: 0 4px 0 #6b140e;
  margin-bottom: 36px;
  font-size: 1.1875rem;
  line-height: 1.47368;

  -webkit-appearance: none;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  margin-top: 0;
  padding: 12px 16px;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: auto;
}

.nhs-button-warning:hover {
  background-color: #a71e14;
}
