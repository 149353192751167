.siteRows:nth-child(odd) {
  background-color: #e8edee;
  cursor: pointer;
  padding: 10px 14px;
}

.siteRows:nth-child(even) {
  background-color: #ffffff;
  cursor: pointer;
  padding: 10px 14px;
}

.organisationNotFound {
  list-style-type: none;
  color: black;
  pointer-events: none;
  border: 1px solid #000000;
  border-top: none;
  padding: 10px;
  font-weight: bold;
}
