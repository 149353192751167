.phase-banner-container {
  background-color: #ffffff;
  padding-bottom: 10px;
}
.phase-banner {
  padding-top: 10px;
}

.phase-banner__content {
  font-family:
    GDS Transport,
    arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.1428571429;
  color: #0b0c0c;
  display: table;
  margin: 0;
}

.phase-banner__text {
  display: table-cell;
  vertical-align: middle;
  padding-left: 8px;
}

.phase-banner__content__tag {
  font-size: 1rem;
  line-height: 1.25;
  margin-right: 15px;
}
