.vaccination-progress .row {
  margin-top: 14px;
  border-bottom: 1px solid #d8dde0;
}

// Summary lists within the narrow right hand column
// should be slightly smaller for now.
.col-md-4 .nhsuk-summary-list {
  font-size: 16px;
}

.actionwidth .nhsuk-summary-list__actions {
  width: 0%;
}

.heading-2 {
  font-size: large;
}
