.nhsuk-panel--error {
  background-color: #f3f3f3;
  margin-top: 0;
  padding: 0px;
  font-size: 18px;
}

.nhsuk-panel__title {
  font-size: 42px;
  margin-bottom: 60px;
}

.nhsuk-panel__subheader {
  font-size: 25px;
  margin-bottom: 20px;
  display: block;
}

.nhsuk-panel__body {
  font-size: 18px;
  margin-bottom: 10px;
}

.nhsuk-panel__body--bold {
  font-weight: bold;
  margin-bottom: 10px;
}

.nhsuk-panel--custom-outline {
  border: 1px solid #ccc;
  padding: 20px;
  margin-top: 20px;
}

.nhsuk-panel__body--extra-space {
  margin-bottom: 20px;
  margin-top: 20px;
}

.nhsuk-panel__body.nhsuk-panel__body--bold {
  font-weight: normal;
}

.nhsuk-panel__body--additional-space {
  margin-top: 30px;
  font-weight: bold;
  font-size: 24px;
}
