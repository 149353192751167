.nhsuk-pagination--numbered:after {
  clear: both;
  content: "";
  display: block;
}

.nhsuk-pagination--numbered__prev,
.nhsuk-pagination--numbered__next,
.nhsuk-pagination--numbered__item {
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  box-sizing: border-box;
  position: relative;
  min-width: 45px;
  min-height: 45px;
  padding: 8px 8px;
  text-align: center;
  margin: 0;
  float: left;
}
@media (min-width: 40.0625em) {
  .nhsuk-pagination--numbered__prev,
  .nhsuk-pagination--numbered__next,
  .nhsuk-pagination--numbered__item {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.47368;
  }
}
@media print {
  .nhsuk-pagination--numbered__prev,
  .nhsuk-pagination--numbered__next,
  .nhsuk-pagination--numbered__item {
    font-size: 13pt;
    line-height: 1.25;
  }
}

.nhsuk-pagination--numbered__link:visited:hover,
.nhsuk-pagination--numbered__item a:hover {
  color: #7c2855;
}

.nhsuk-pagination--numbered__prev:hover,
.nhsuk-pagination--numbered__next:hover,
.nhsuk-pagination--numbered__item:hover {
  background-color: #d8dde0;
  color: #005eb8;
}

.nhsuk-pagination__list--numbered {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nhsuk-pagination--numbered__item--current,
.nhsuk-pagination--numbered__item--current:hover {
  background-color: #005eb8;
  font-weight: 600;
}
.nhsuk-pagination--numbered__item--current .nhsuk-pagination--numbered__link,
.nhsuk-pagination--numbered__item--current:hover
  .nhsuk-pagination--numbered__link {
  color: #ffffff;
}
.nhsuk-pagination--numbered__item--current
  .nhsuk-pagination--numbered__link:focus,
.nhsuk-pagination--numbered__item--current:hover
  .nhsuk-pagination--numbered__link:focus {
  color: #212b32;
}

.nhsuk-pagination--numbered__item--ellipses {
  font-weight: 600;
  color: #4c6272;
}
.nhsuk-pagination--numbered__item--ellipses:hover {
  background-color: transparent;
}

.nhsuk-pagination--numbered__link {
  min-width: 16px;
  vertical-align: middle;
  color: #005eb8;
}
@media screen {
  .nhsuk-pagination--numbered__link::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.nhsuk-pagination--numbered .nhsuk-icon {
  margin-top: 0;
  fill: #005eb8;
}

.nhsuk-pagination--numbered__link:visited .nhsuk-icon {
  fill: #330072;
}

.nhsuk-pagination--numbered__link:hover .nhsuk-icon {
  fill: #7c2855;
}

.nhsuk-pagination--numbered .nhsuk-icon__arrow-left {
  margin-left: -13px;
  margin-right: 8px;
}

.nhsuk-pagination--numbered .nhsuk-icon__arrow-right {
  margin-left: 8px;
  margin-right: -13px;
}
