@import "node_modules/nhsuk-frontend/packages/core/settings/all";
@import "node_modules/nhsuk-frontend/packages/core/tools/all";

.ravs-button--loading {
  position: relative;
  -webkit-transition: width 5s ease;
  transition: width 5s ease;
}

.ravs-button--loading:before {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: -9px;
  height: 25px;
  width: 25px;
  background: transparent
    url('data:image/svg+xml,<svg height="200" viewBox="0 0 200 200" width="200" xmlns="http://www.w3.org/2000/svg">%0A  <g fill="none" fill-rule="evenodd">%0A    <path%0A        d="m100 0v13.0000106c-.0149985-.0000068-.0299979-.0000106-.0449982-.0000106-48.0239214 0-86.9550018 38.9310804-86.9550018 86.9550018 0 48.0239212 38.9310804 86.9550022 86.9550018 86.9550022 48.0089212 0 86.9306812-38.906764 86.9549902-86.910004h13.090008c0 55.228475-44.771525 100-100 100s-100-44.771525-100-100 44.771525-100 100-100z"%0A        fill="%23e5e5e5"/>%0A    <path%0A        d="m0 100h13.0000106zm100 86.909993v13.090007zm100-86.909993h-13.090007c.000007-.0149985.000011-.0299979.000011-.0449982 0-48.0089211-38.906764-86.9306807-86.910004-86.9549904v-13.0000114c55.228475 0 100 44.771525 100 100z"%0A        fill="%23367c42"/>%0A  </g>%0A</svg>')
    no-repeat;
  background-size: 25px 25px;
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  animation-timing-function: ease;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.ravs-button--loading[disabled],
.ravs-button--loading[disabled]:hover {
  background-color: #44834d;
  opacity: 1;
  -webkit-box-shadow: 0 2px 0 #88988c;
  box-shadow: 0 2px #88988c;
}
