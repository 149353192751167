.nhsuk-header__navigation-list {
  justify-content: normal !important;
}

.cutoff-nav {
  margin-left: auto;
}

.nhsuk-skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #005eb8;
  color: white;
  padding: 8px;
  z-index: 100;
  transition: top 0.3s;

  &:focus {
    top: 0;
  }
}

.default-color {
  color: #005eb8;
  text-decoration: underline;
}

.hovered-color {
  text-align: left;
  color: darkblue;
  text-decoration: none;
}

.error-color {
  text-align: left;
  color: #d5281b;
  text-decoration: underline;
}

.anchor-style {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.anchor-style:focus {
  outline: none;
  text-decoration: none;
  color: black;
  background-color: #ffeb3b;
  text-decoration: none;
}

.anchor-style:focus::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 4px;
  background-color: black;
}

.table-caption-fix {
  caption-side: top;
  color: #212b32;
}

.radios-dropdown {
  margin-bottom: 15px;
  margin-left: 20px;
  padding-left: 35px;
  border-left: 4px solid #b1b4b6;
}

.timeout-warning {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 450px;
}

.timeout-warning--focused {
  outline: 2px solid #fd0;
  outline-offset: 0;
  box-shadow: inset 0 0 0 2px;
}

.timeout-warning--focused .button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* Centers buttons horizontally */
  align-items: center;
  /* Centers buttons vertically */
  gap: 80px;
  /* Space between the buttons */
}

.button-wrapper {
  width: 100%;
  /* Make the button full-width if desired */
  text-align: center;
}

.emailSentContainer {
  max-width: 100%;
  word-break: break-all;
}
