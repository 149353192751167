.app-email-preview {
  margin-top: 20px;
  border: 5px solid #b1b4b6;
  padding: 20px;
  margin-bottom: 30px;
  h2 {
    font-size: 19px;
    margin: 0 0 15px 0;
    padding: 10px 0 0 0;
  }
  p {
    font-size: 19px;
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 100%;
    overflow: auto;

    /* Reset font styles to match the rest of the page */
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
}
